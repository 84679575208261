<template>
  <div class="header" id="header-panel">
    <!-- 加一层容器 -->
    <div class="header-wrap">
      <a class="logo" href="/">
        <img src="../../assets/images/logo.png" alt="logo" style="cursor: pointer" width="100%" />
      </a>
      <i :class="drawer ? 'el-icon-s-fold' : 'el-icon-s-unfold'" @click="drawer = !drawer"
        style="right: 0;position: absolute;"></i>

      <el-drawer custom-class="drawer-menu" size="100%" direction="ltr" :visible.sync="drawer" :with-header="false"
        :append-to-body="true" :modal="false" :wrapperClosable="true">
        <ul class="menu-drawer">
          <li class="menu-item" v-for="(item, index) in menus" :key="index" :class="{ active: getCurrentMenu(item) }">
            <div v-if="!item.children.length" @click="toUrl(item.router, item.point)" class="first-item"
              style="border-top: 1px solid white;">
              {{ item.name }}
            </div>
            <el-collapse v-else accordion>
              <el-collapse-item :name="index">
                <template slot="title">
                  <p class="first-item" style="margin-left: 21px;">{{ item.name }}</p>
                </template>
                <div v-for="(child, index) in item.children" :key="index">
                  <div @click="toUrl(child.router, child.point)" class="second-item">{{ child.name }}</div>
                  <div v-if="child.children.length">
                    <el-row>
                      <el-col :span="12" v-for="(sub, index) in child.children" :key="index">
                        <div class="third-item" @click="toUrl(sub.router, sub.point)">{{ sub.name }}</div>
                      </el-col>
                    </el-row>
                  </div>
                </div>
              </el-collapse-item>
            </el-collapse>
          </li>
          <li class="menu-item" :class="{ active: getCurrentMenu({ router: '/search' }) }">
            <div @click="toUrl('/search')" class="first-item" style="border-top: 1px solid white;">
              搜索
            </div>
          </li>
          <li class="menu-item" v-if="!userInfo">
            <div @click="handleLogin" class="first-item" style="border-top: 1px solid white;">
              登录
            </div>
          </li>
        </ul>
      </el-drawer>
      <ul class="menu">
        <li class="menu-item" v-for="(item, index) in menus" :key="index" @mouseenter="enter(item.children,item)"
          @mouseleave="leave">
          <a @click="toUrl(item.router, item.point)">{{ item.name }}</a>
        </li>
      </ul>
      <div class="search">
        <div class="search-content">
          <el-input placeholder="搜索" v-model="request.content" @keyup.enter.native="handleSearch()">
            <el-button slot="prepend" icon="el-icon-search" @click="handleSearch()"></el-button>
          </el-input>
          <el-button class="button" @click="toUrl('/download')"><i class="el-icon-download" style="font-size: 18px;"></i>
            MWORKS下载</el-button>
        </div>
      </div>
      <div class="user" v-if="userInfo">
        <!-- 语言切换 -->
        <!-- <div class="language">
          <span @click="changeLang">{{ language === "zh" ? "En" : "中" }}</span>
        </div> -->
        <el-dropdown trigger="click" v-if="userInfo" size="medium">
          <div class="el-dropdown-link">
            <el-avatar class="avatar" :src="userInfo.picture" :size="40" :title="userInfo.sub">
              {{ userInfo.sub.substr(0, 1).toUpperCase() }}
            </el-avatar>
            <i class="el-icon-arrow-down"></i>
          </div>
          <el-dropdown-menu slot="dropdown" :append-to-body="false">
            <div style="padding: 0 17px;">{{ userInfo.sub }}</div>
            <el-dropdown-item @click.native="toTicket('new')" style="margin: 8px 0;" divided>新建工单</el-dropdown-item>
            <el-dropdown-item @click.native="toTicket('mine')" style="margin: 8px 0;">我的工单</el-dropdown-item>
            <el-dropdown-item @click.native="handleLoginOut" divided>{{
              $t("header.signOut")
            }}</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
      <div v-else class="btn-wrap">
        <!-- <span class="language">
          <span @click="changeLang">{{ language === "zh" ? "En" : "中" }}</span>
        </span> -->
        <el-button type="primary" size="small" @click="handleLogin" round>{{ $t("header.login") }}
          <!-- / {{ $t("header.register") }} -->
        </el-button>
      </div>
    </div>
    <submenu v-show="seen" :data="tag"></submenu>
  </div>
</template>

<script>
import router from '@/router';
import submenu from './components/submenu.vue';
import * as api from '@/service/api';
import store from '../../store/index';

export default {
  components: { submenu },
  data() {
    return {
      activeName: '1',
      fullWidth: 0,
      seen: false,
      request: {
        content: ''
      },
      menus: [],
      tag: {
        parent: '',
        submenus: []
      },
      routeList: [],
      subrouteList: [],
      home: [],
      avatarUrl:
        'https://cube.elemecdn.com/3/7c/3ea6beec64369c2642b92c6726f1epng.png',
      userInfo: null,
      searchValue: '', // 搜索模型的值
      isdot: false, // 小红点
      language: this.$i18n.locale,
      drawer: false,
      windowWidth: 0
    };
  },
  watch: {
    '$route.path': {
      handler() {
        this.loadUserInfo();
      }
    }
  },
  created() {
    this.getRouters().then(() => {
      for (let i = 0; i < this.routeList.length; i++) {
        this.addRoute(this.routeList[i], this.routeList[i]);
      }
      this.addNoRoute();
    });
    this.getHeader();
  },
  async mounted() {
    this.getWindowWidth(); // 在初始化时获取窗口高度
    window.addEventListener('resize', this.getWindowWidth);
    // window.onresize = () => {
    //   return (() => {
    //     if (document.documentElement.clientWidth > 700) {
    //       this.drawer = false;
    //     }
    //   })();
    // };
    await this.getUserInfo();
    this.userInfo = this.$utils.getStorage('userInfo');
    console.log('从内存中获得用户信息', this.userInfo);
    if (this.userInfo) {
      this.$bus.$on('avatarRefresh', () => {
        this.loadUserInfo();
      });
    }
    // document.addEventListener("scroll", (e) => {
    //   const drawer = document.getElementsByClassName("el-drawer__wrapper");
    //   if (drawer) {
    //     const height = 0;
    //     const scrollTop =
    //       e.target.documentElement.scrollTop ||
    //       window.pageYOffset ||
    //       document.body.scrollTop;
    //     drawer[0].style.top =
    //       scrollTop > height ? "60px" : `${height + 60 - scrollTop}px`;
    //   }
    // });
    this.$root.$on('notice', () => {
      this.checkDot();
    });
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.getWindowWight);
    // 销毁刷新用户信息事件
    this.$bus.$off('avatarRefresh');
    this.$bus.$off('notice');
  },
  computed: {
    getCurrentMenu() {
      return (item) => {
        if (this.$route.path === item.router) {
          return true;
        }
        return false;
      };
    }
  },
  methods: {
    getWindowWidth() {
      this.windowWidth = window.innerWidth;
      if (this.windowWidth > 700) {
        this.drawer = false;
      }
    },
    handleSearch(key) {
      key = key || this.request.content;
      if (!key) {
        return;
      }
      this.$router.push('/search?key=' + key);
      this.request.content = '';
    },
    async getRouters() {
      const res = await this.$api.getRouters();
      if (res.code == 200) {
        this.routeList = res.data;
      }
    },
    addRoute(path, title) {
      router.addRoutes([
        {
          path: path,
          title: title,
          name: title,
          component: () => import(/* webpackChunkName: "about" */ '@/views/view')
        }
      ]);
    },
    addNoRoute() {
      router.addRoutes([
        {
          path: '*',
          redirect: '/404'
        }
      ]);
    },
    enter(item, parent) {
      this.seen = true;
      this.tag.parent = parent;
      this.tag.submenus = item;
    },
    leave() {
      this.seen = false;
    },
    async getUserInfo() {
      let token = this.$utils.getToken();
      if (token) {
        console.log('当前存在token, 需刷新userInfo');
        const res = await api.userinfo().catch(err => {
          console.log('获取用户信息失败', err);
          store.dispatch('app/setUserInfo', null);
          return;
        });
        console.log('成功获取用户信息', res);
        store.dispatch('app/setUserInfo', res.data);
      }
    },
    async getHeader() {
      const res = await this.$api.getHeader();
      if (res.code == 200) {
        this.menus = res.data;
        console.log(this.menus);
      }
    },
    async getHomePage() {
      const res = await this.$api.getHomePage();
      if (res.code == 200) {
        this.home = res.data;
      }
    },
    toUrl(url, point) {
      this.drawer = false;
      if (url.includes('http')) {
        window.open(url);
      } else {
        if (point) {
          this.$router.push(url + point);
        } else {
          this.$router.push(url);
        }
      }
    },
    handleLogin() {
      // this.$utils.setPath(this.$route.path);
      this.$utils.openLogin({ path: this.$route.path });
    },
    handleLoginOut() {
      this.$confirm(this.$t('header.sure'), this.$t('common.tip'))
        .then(() => {
          this.$router.push('/');
          this.$utils.loginOut();
          this.userInfo = null;
          //     if (this.$route.path === '/support/ticket') {
          //   this.toTicket()
          // }
          // location.reload();
        })
        .catch(() => { });
    },
    /**
     * 更新用户信息
     */
    loadUserInfo() {
      console.log('loadUserInfo更新用户信息');
      this.userInfo = this.$utils.getStorage('userInfo');
    },
    /**
     * 去个人消息
     */
    toNotice() {
      if (!this.$utils.isLogin()) {
        this.$utils.openLogin();
        return;
      }
      this.$utils.toUrl('/notice');
    },
    toTicket(path) {
      if (!this.$utils.isLogin()) {
        this.$utils.openLogin();
        return;
      } else {
        this.$router.push(`/support/ticket?path=${path}`);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.header {
  min-width: 1190px;
  // @include background();
  min-height: $--header-height;
  background: $--background-color-banner;

  // box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.1);
  // color: $--color-white;
  .header-wrap {
    height: $--header-height;
    min-width: 715px;
    max-width: 1250px;
    // margin: 0 0 0 auto;
    margin: 0 auto;
    display: flex;
    align-items: center;
    user-select: none;
    position: relative;
  }
}

.logo {
  width: 150px;
  @include flex(row, center, center);
  // margin-right: 40px;
  justify-content: unset;
  text-decoration: unset;
  font-size: 18px;
  font-weight: 600;
  color: $--color-white;

  img {
    margin-right: 15px;
  }
}

.el-icon-s-unfold,
.el-icon-s-fold {
  color: #fff;
  display: none;
  font-size: 10vw;
  cursor: pointer;
}

.menu-drawer {
  line-height: 45px;

  .menu-item {
    cursor: pointer;

    .first-item {
      width: 100%;
      height: 50px;
      font-size: 20px;
      cursor: pointer;

      &:hover {
        color: $--color-primary;
      }
    }

    .second-item {
      text-align: left;
      font-size: 19px;
      cursor: pointer;
      padding: 5px 5%;
      font-weight: bold;

      &:hover {
        color: $--color-primary;
      }
    }

    .third-item {
      text-align: left;
      font-size: 16px;
      cursor: pointer;
      padding: 5px 10%;

      &:hover {
        color: $--color-primary;
      }
    }
  }
}

.menu {
  @include flex(row, null, center);
  height: 100%;
  // border-left: 2px solid #000;
  // border-right: 2px solid #000;
  // padding: 0 10px;

  .menu-item {
    min-width: 90px;
    margin-top: 37px;
    padding-bottom: 30px;
    position: relative;

  }

  .menu-item a {
    color: #f7f8f8;
    font-size: 14px;
    height: 40px;
    line-height: 40px;
    border: 2px solid #000000;
    border-radius: 22px;
    padding: 10px 15px;
    cursor: pointer;

    &:hover {
      border: 2px solid #508cc3;
      color: #FFFFFF;
    }
  }

  .sub-menu {
    position: absolute;
    left: 2%;
    top: 99%;
    right: -48%;
    background-color: #1d1d1d;
    opacity: 0;
    visibility: hidden;
    z-index: -1;
    -webkit-transition: 0.3s;
    transition: 0.3s;
    -webkit-transform: translateY(-10px);
    -ms-transform: translateY(-10px);
    transform: translateY(-10px);
    padding: 5% 0 12% 0%;
  }
}

.search-repo {
  flex: 1;
  text-align: right;

  /deep/.el-input {
    width: 250px;

    .el-input__inner {
      border-radius: 8px;
    }
  }
}

.user {
  margin: 10px 0 0 30px;
  position: absolute;
  right: 0;

  .avatar {
    margin-right: 5px;
    cursor: pointer;
  }

  .el-badge {
    // margin-right: 30px;
  }

  .ty-notice {
    font-size: 24px;
    cursor: pointer;
    color: $--color-white;
  }

  .ty-notice:hover {
    color: $--color-primary;
  }
}

.language {
  margin: -2px 20px 0;
  color: #fff;
  cursor: pointer;
  font-size: 18px;

  &:hover {
    color: $--color-primary;
  }
}

.btn-wrap {
  // @include flex(row, center, center);
  // width: 400px;
  // justify-content: flex-end;
  margin: 10px 5px 0 20px;
  position: absolute;
  right: 0;

  .language {
    margin: 0 20px;
  }
}

/deep/ .el-dropdown {
  .el-dropdown-link {
    color: $--color-white;
    cursor: pointer;
    font-size: 20px;
    white-space: nowrap;

    i {
      vertical-align: 55%;
    }
  }
}

.login-button {
  color: $--color-white;
}

.search {
  display: flex;
  align-items: center;
  justify-content: center;

  &-content {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    width: 300px;
    background-color: #3d3d42;
    margin: 10px 5px 0 5px;
    height: 50px;

    /deep/ .el-input-group__prepend {
      color: #fff;
      border: none;
      background: none;
      height: 50px;

      .el-button {
        width: 20px;
      }
    }

    /deep/ .el-input__inner {
      color: #fff;
      border: none;
      background: none;
      height: 50px;
    }

    .button {
      height: 50px;
      border: none;
      background-color: #497dcc;
      color: white;

      &:hover {
        color: #fff;
        background-color: #2a63b7;
        border-color: #497dcc;
      }
    }
  }
}

/deep/ .el-collapse-item__header {
  background-color: #393e49;
  border-bottom: none;
  border-top: 1px solid white;
  color: #fff;
  height: 50px;
}

/deep/ .el-collapse {
  border-top: none;
  border-bottom: none;
}

/deep/ .el-collapse-item__wrap {
  background-color: #393e49;
  color: #fff;
}

/deep/ .el-collapse-item__content {
  color: #fff;
}
</style>
